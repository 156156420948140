.user-info {
    .user-avater {
        img {
            height: 4rem;
            width: 4rem;
            &.edit-circle {
                height: 1.5rem;
                width: 1.5rem;
                bottom: 0;
                right: 0;
            }
        }
    }
}
/*Start Education*/
h5.degree-name, h5.position-name{
	font-weight: $font-w-medium;
}
.company, .degree-school, .institution-location {
    color: $Black-3;
    font-weight: $font-w-regular;
}
/*End Education*/

/*Start Skills*/
.w-20{width: 20%;}
.w-40{width: 40%;}
.w-60{width: 60%;}
.w-80{width: 80%;}
.skills {
}
/*End Skills*/

.progress-s-1{
	background-color: rgba(45, 190, 110, 0.1);
	/* border: 1px solid #0078D7; */
	height: 10px;
	border-radius: 20px;
}
.progress-s-1 .progress-bar{
	background-color: #2dbe6e;
}