// ----------------------------------------------------------------------------------
// This file contains styles that are specific to the create resume/coverletter page.
// ----------------------------------------------------------------------------------
.createrc {
    margin-top: 2rem;
 .template-list {
    button {
        &.template {
            &.active {
                .thumbnail {
                    border: 2px solid $Primary-1 !important;
                }
            }
            &:focus {
                outline: none;
            }
            .thumbnail {
                border-radius: $br-medium;
                width: 100%;
            }
            .title {
        
            }
        }
    }
    
 }   
}