.navbar-nav {
    .badge-premium {
        top: 8px;
        left: 12px;
        background-color: $Pink-1;
        padding: 2px;
        border-radius: 40px;
    }
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -140px;
}

@media (max-width: 991.98px) {
    .navbar-nav {
        .badge-premium {
            left: 3px;
        }
    }
    .navbar-nav {
        .dropdown-menu {
            position: absolute;
            right: -140px;
        }
    }
}