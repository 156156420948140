// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.hero-slider {
    .promo-graphic {

    }
    .btn {
        &.position-absolute {
            left: 0;
            right: 0;
            width: 180px;
            margin: 0 auto;
            bottom: 195px;
        }
    }
}
.resume-slider{
    .slick-slider {
        .slick-prev {
            left: 20%;
            z-index: 1;
            top: 40%;
            &::before {
                font-size: 48px;
                opacity: .1;
            }
            &:hover {
                &::before {
                    opacity: .5;
                }
            }
        }
        .slick-next {
            right: 20%;
            z-index: 1;
            top: 40%;
            &::before {
                font-size: 48px;
                opacity: .1;
            }
            &:hover {
                &::before {
                    opacity: .5;
                }
            }
        }
        .slick-list {
            height: 520px;
            padding-top: 20px !important;
        }
        .slick-slide {
            .rh-info {
                .badge-tertiary {
                    padding-right: 5px !important;
                }
            }
            .rh-st {
                opacity: 0;
                margin: 0 auto;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 51%;
                width: 180px;
                margin-bottom: -25px;
                transition: opacity 1s ease;
                transition: margin-bottom 0.5s ease;
            }
            .rh-thumbnail {
                width: 85%;
                height: auto;
                margin: 0 auto;
                display: flex;
                // border: 2px solid $Grey-5;
                margin-top: 11%;
            }
            .rh-container {
                opacity: .4;
            }
            &.slick-center {
                .rh-container {
                    opacity: 1;
                    position: relative;
                    &:hover {
                        .rh-st {
                            transition: opacity 1s ease;
                            opacity: 1;
                            margin-bottom: 0;
                            transition: margin-bottom 0.5s ease;
                        }
                        .rh-thumbnail {
                            
                        }
                    }
                }
                .rh-thumbnail {
                    width: 100%;
                    margin-top: 0;
                    -webkit-box-shadow: 0 8px 26px rgba(59, 59, 59, 0.19),0 11px 33px rgba(59,59,59,.26);
                    box-shadow: 0 8px 26px rgba(59,59,59,.19),0 11px 33px rgba(59,59,59,.26);
                }
                .rh-info {
                    h5 {
                        // margin-top: 0 !important;
                    }
                }
            }
        }
    }

    padding-top: $p-5xl;
    padding-bottom: $p-5xl;
    h4 {
        color: $Orange-1;
    }
    h2 {
        margin-bottom: $m-xl;
    }
    p {
        margin-bottom: $m-3xl;
    }
    .slick-prev::before, .slick-next::before {
        color: $Black-1;
    }
}
.benefits {
    padding-top: $p-9xl;
    padding-bottom: $p-9xl;
}

/* How it works */
.hw{
	padding: $p-9xl 0;
}
.slick-dots {
	position: relative;
    li {
        position: relative;
        display: inline-block;
        margin: 0 $m-base;
        padding: 0;
        cursor: pointer;
        font-size: $font-s-base;
        line-height: 1.725rem;
        div {
            border-radius: $br-base !important;
            width: 2rem !important;
            height: 2rem !important;
        }
        &.slick-active {
            div{
                width: 2rem !important;
                height: 2rem !important;
                color: $White-1 !important;
                border: 3px solid $Black-1;
                background: $Black-1;
            }
        }
    }
}

.home-c2a{
	padding: $p-9xl 0 $p-9xl*6 0;
    background-image: url(../../../src/img/bg-c2a.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
}

.slick-slide.slick-center img { margin-left: auto; margin-right: auto; }

@media (max-width: 991.98px) {
    .hero-slider {
        .btn {
            &.position-absolute {
                bottom: 30px;
            }
        }
    }
    .stats {
        .icon {
            height: 48px;
            width: 48px;
        }
    }
    .benefits {
        padding-top: 2.25rem;
        padding-bottom: 2.25rem;
    }
    .hw {
        padding: 1.25rem;
    }
    .slick-dots {
        bottom: 0;
        ul {
            padding: 0;
        }
    }
    .home-c2a {
        background-image: none;
        padding: 1.25rem 0;
    }
    .resume-slider {
        .slick-slider {
            .rh-st {
                opacity: 1 !important;
                margin-top: 15px !important;
                width: auto !important;
                margin-bottom: 0 !important;
                position: relative !important;
            }
            .slick-list {
                height: auto;
            }
            .slick-next {
                right: 0%;
                top: 45%;
                &::before {
                    font-size: 24px;
                    opacity: 1;
                }
            }
            .slick-prev {
                left: 0%;
                top: 45%;
                &::before {
                    font-size: 24px;
                    opacity: 1;
                }
            }
        }
    }
}