.btn {
    &.template-container {
        img {
            border: 2px solid #e0e0e0;
            border-radius: $br-xl;
            &:hover {
                border: 2px solid $Primary-1;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
}

