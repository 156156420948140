// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn-primary:not(:disabled) {
    &:hover {
        background-color: $Primary-2;
    }
}
.btn {
    padding: $p-medium $p-large;
    border-radius: $br-base;
    font-size: $font-s-small;
    font-weight: $font-w-semibold;
}
.btn-sm {
    padding: $p-medium $p-medium !important;
    font-size: .75rem;
}
.btn-lg {
    padding: $p-large  $p-large;
}

%btn-outline-hfa {
    background-color: $White-1 !important;
    border: 2px solid $Black-1;
}
.btn-outline-dark-circle {
    height: 2.25rem;
    width: 2.25rem;
    border: 2px solid rgba(23, 34, 59, 0.1);
    line-height: 2rem;
    &:hover {
        @extend %btn-outline-hfa;
    }
    &:focus {
        @extend %btn-outline-hfa;
    }
    &:active {
        @extend %btn-outline-hfa;
    }
}
.btn-primary {
    color: $White-1;
    background-color: $Primary-1;
    border-color: $Primary-1;
}
.btn-outline-primary {
    background-color: $Blue-Grey-5;
    &:hover {
        background-color: $Primary-2;
    }
}
.btn-light {
    color: $Black-1;
    background-color: $Grey-5;
    border-color: $Grey-5;
}
a {
    &.close {
        &.circle {
            background-color: $Grey-3;
            height: 2.5rem;
            width: 2.5rem;
            line-height: 0;
            &.fixed {
                position: absolute;
                top: -1.875rem;
                right: -1.875rem;
            }
            .icon-times {
                height: 1.125rem;
            }
        }
    }
}
/* SUPPORT BUTTON FIXED BOTTOM RIGHT */
.btn-floating {
    position: fixed; 
    z-index: 1;
    &.floating-bottom-right {
        right: 1.25rem; 
	    bottom: 2.5rem;
    }
    &.floating-bottom-center {
	    bottom: 2.5rem;
    }
}

@media (max-width: 991.98px) {
    .btn {
        font-size: .8125rem;
    }
    .btn-outline-dark-circle {
        height: 3.25rem;
        width: 3.25rem;
        line-height: 3rem;
    }
}