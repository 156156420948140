/* PAGE MODAL */
.page-modal:before{
	content: '';
	height: 100%;
	background-color: #DADCE0;
	display: block;
    position: fixed;
    width: 100%;
	z-index: -1;
}
.page-modal{
	top: 0;
	bottom: 0;
	z-index: 1;
}
.page-modal .action-panel{
	z-index: 1;
	height: 72px;
}
.page-modal .action-btn-group .btn{
	height: 48px;
	width: 48px;
	line-height: 44px;
}
.page-modal .page-body{
	margin-top: 8rem;
}