// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
    background-color: $Grey-4;
    ul {
        li {
            a {
                font-size: 1rem;
            }
        }
    }
    .footer-social-link a{
        height: 3rem;
        width: 3rem;
        border: 2px solid $Black-1;
        &:hover {
            background-color: rgba(23, 34, 59, 0.05);
            svg{
                fill: $White-1;
            }
        }
    }
}

@media (max-width: 991.98px) { 
    .footer-bottom {
        .icon-memorial {
            height: 18px;
        }
    }
}