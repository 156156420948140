// -----------------------------------------------------------------------------
// This file contains styles that are specific to the subscription page.
// -----------------------------------------------------------------------------
.invoice-list {
    .invoice-number {
        font-weight: $font-w-semibold;
        color: $Black-1;
    }
    .invoice-date {
        .date {
            font-weight: $font-w-semibold;
            color: $Black-1;
            margin-left: .375rem;
        }
    }
}