.card {
    &.rt {
        transition-duration: 500ms;
        &:hover {
            -webkit-box-shadow: 0 8px 26px rgba(59, 59, 59, 0.19),0 11px 33px rgba(59,59,59,.26);
            box-shadow: 0 8px 26px rgba(59,59,59,.19),0 11px 33px rgba(59,59,59,.26);
            transition-duration: 500ms;
        }
        .card-body {
            .btn-rs {
                margin: 0 auto;
                left: 0;
                right: 0;
                width: 50%;
                bottom: 42%;
                opacity: 0;
                margin-bottom: -25px;
                transition: margin-bottom 0.5s ease;
            }
            &:hover {
                .btn-rs {
                    opacity: 1;
                    margin-bottom: 0px;
                    transition: margin-bottom 0.5s ease;
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .card {
        &.rt {
            .card-body {
                .btn-rs {
                    opacity: 1;
                    margin-bottom: 0;
                }
            }
        }
    }
}